import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "../styles/footer.scss";

import Header from "./header";
import Footer from "./footer";
import { graphql, useStaticQuery } from "gatsby";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const Layout = (props) => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("cookieConsentShown")) {
      setShowCookieConsent(true);
      localStorage.setItem("cookieConsentShown", true);
    }
  });
  const data = useStaticQuery(graphql`
    query Layout {
      allStrapiLayout {
        edges {
          node {
            locale
            navbar {
              whyopsio {
                name
                value
              }

              industry {
                name
                value {
                  path
                  val
                }
              }

              platform {
                value {
                  path
                  val
                }
                name
              }
              contact
              search
              searchNoOptions
              resources {
                name
                value
              }
              footer {
                heading
                contact {
                  email
                  phone
                  address1
                  address2
                }
                values {
                  COMPANY {
                    name
                    src
                  }
                  INDUSTRIES {
                    name
                    src
                  }
                  PLATFORMS {
                    name
                    src
                  }
                  RESOURCES {
                    name
                    src
                  }
                  SERVICES {
                    name
                    src
                  }
                  TECHNOLOGIES {
                    name
                    src
                  }
                  TECHNOLOGIES2 {
                    name
                    src
                  }
                }
              }
              suggestion

              solution {
                name
                data {
                  name
                  services {
                    name
                    value {
                      name
                      path
                    }
                  }
                  technologies {
                    name
                    path
                  }
                  technologies2 {
                    name
                    path
                  }
                  value
                }
              }
              requirements {
                btn
                email
                des
                title
              }
            }
          }
        }
      }
    }
  `).allStrapiLayout.edges;
  // const [langKey, setLangKey] = useState("");

  const getData = data.filter((node) => node.node.locale === props.locale);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcbmXUdAAAAAM8BACZCq5rzXPHfn1BpVeNv5f5o">
      <Header
        locale={props.locale}
        slug={props.slug}
        headerData={getData.length > 0 && getData[0].node.navbar}
      />
      {props.children}
      <Footer
        data={getData.length > 0 && getData[0].node.navbar.footer}
        requirements={getData.length > 0 && getData[0].node.navbar.requirements}
        locale={props.locale}
        hideCta={props.hideCta}
      />
    </GoogleReCaptchaProvider>
  );
};

export default Layout;
