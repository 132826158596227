import * as React from "react";
import $ from "jquery-slim";
import { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  InputGroup,
  FormControl,
  Tab,
} from "react-bootstrap";
import opsioLogo from "../images/opsio-logo.svg";
import searchIcon from "../images/searchIcon.svg";
import servicemenuIocn from "../images/servicemenuIocn.svg";
import LatestnewsMenu from "../images/assets/latestnewsMenu.svg";
import BlogsMenu from "../images/assets/blogsMenu.svg";
import PressnewsMenu from "../images/assets/pressnewsMenu.svg";
import WhitePapermenu from "../images/assets/whitePapermenu.svg";
import technologiesmenuIcon from "../images/technologiesmenuIcon.svg";
import LanguageIcon from "./LanguageIcon";
import HumbergerMenu from "../images/assets/humbergermenu.svg";
import { Link } from "gatsby";
import MediaQuery from "react-responsive";
import Select, { components } from "react-select";

import Scrollbars from "react-custom-scrollbars";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Helmet } from "react-helmet";

const Header = ({ slug, locale, headerData }) => {
  const flags =
    locale === "sv"
      ? {
        en: "Engelska(US)",
        "en-IN": "Engelska(IN)",
        sv: "Svenska(SE)",
      }
      : {
        en: "English(US)",
        "en-IN": "English(IN)",
        sv: "Swedish(SE)",
      };
  if (typeof window !== "undefined") {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".mina_header").addClass("stickyHeader");
      } else {
        $(".mina_header").removeClass("stickyHeader");
      }
    });
  }

  const initialLang = flags[locale];
  const [device, setDevice] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showIndustryList, setShowIndustryList] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [langTitle, setLangTitle] = useState(initialLang);
  const [searchValue, setSearchValue] = useState();
  const [toggleMenuBtn, setToggleMenuBtn] = useState("defaultBtn22");
  const handleToggleBtn = () => {
    if (toggleMenuBtn === "defaultBtn22") {
      setToggleMenuBtn("overfHide");
    } else {
      setToggleMenuBtn("defaultBtn22");
    }
  };

  React.useEffect(() => {
    const handler = () => {
      setDevice(window.innerWidth <= 1024 ? "mobile" : "desktop");
    };

    handler();
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  });

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const showDropdown1 = (e) => {
    setShow1(!show1);
  };
  const hideDropdownIndustryList = (e) => {
    setShowIndustryList(false);
  };
  const showDropdownIndustryList = (e) => {
    setShowIndustryList(!showIndustryList);
  };
  const hideDropdown1 = (e) => {
    setShow1(false);
  };
  const showDropdown2 = (e) => {
    setShow2(!show2);
  };
  const hideDropdown2 = (e) => {
    setShow2(false);
  };
  const showDropdown3 = (e) => {
    setShow3(!show3);
  };
  const hideDropdown3 = (e) => {
    setShow3(false);
  };
  const getResourcesList = headerData ? headerData.resources.value : [];
  const getSolutionList = headerData ? headerData.solution.data : {};
  const serviceList = headerData ? headerData.solution.data.services : [];

  const technologyList = headerData
    ? headerData.solution.data.technologies
    : [];

  const technologyList2 = headerData
    ? headerData.solution.data.technologies2
    : [];

  const styles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: state.isFocused ? null : null,
      width: "199px",
    }),
    input: (base, state) => ({
      ...base,
      color: "#FFF",
    }),
    singleValue: () => ({
      color: "#FFF",
    }),
    option: (base, state) => ({
      ...base,
      fontSize: "0.8vw",
      fontStyle: "Open Sans",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }),
    indicatorSeparator: () => { }, // removes the "stick"
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            src={searchIcon}
            alt="opsioLogo"
            onClick={() => handleKeyDown("search")}
          />
        </components.DropdownIndicator>
      )
    );
  };
  const data = useStaticQuery(graphql`
    query {
      allStrapiBlogs {
        edges {
          node {
            locale
            slug
            title
            heading
          }
        }
      }
      allStrapiPressmedias {
        edges {
          node {
            locale
            slug
            title
            heading
          }
        }
      }
      allStrapiCloudnews {
        edges {
          node {
            locale
            slug
            title
            heading
          }
        }
      }
    }
  `);
  const getallcloudnewsblog = data.allStrapiCloudnews.edges
    .filter((node) => node.node.locale === locale)
    .map((val) => ({
      value: `/resource/cloud-news/${val.node.slug}`,
      label: val.node.heading,
    }));
  const getallblogs = data.allStrapiPressmedias.edges
    .filter((node) => node.node.locale === locale)
    .map((val) => ({
      value: `/resource/press-and-media/${val.node.slug}`,
      label: val.node.heading,
    }));
  const getallPressMedia = data.allStrapiBlogs.edges
    .filter((node) => node.node.locale === locale)
    .map((val) => ({
      value: `/resource/blog/${val.node.slug}`,
      label: val.node.heading,
    }));
  const options = [...getallPressMedia, ...getallblogs, ...getallcloudnewsblog];
  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event === "search") {
      const path = ``;
      navigate(`${path}${searchValue.value}`);
    }
  };
  const renderScrollbar = (props) => {
    return (
      <div style={{ height: 300 }}>
        <Scrollbars>{props.children}</Scrollbars>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <html lang={locale} />
        <script
          type="text/javascript"
          src="https://www.bugherd.com/sidebarv2.js?apikey=b5pqagka5nagolq9lfme8a"
          async="true"
        ></script>
        <body className={toggleMenuBtn} />
      </Helmet>
      <div className="mina_header">
        <header className="opsioHeader">
          <Navbar collapseOnSelect expand="lg" variant="dark">
            <Navbar.Brand>
              <Link to={`/`}>
                <img src={opsioLogo} alt="opsioLogo" />
              </Link>
            </Navbar.Brand>

            <Navbar.Toggle
              onClick={handleToggleBtn}
              ria-controls="responsive-navbar-nav"
            >
              <HumbergerMenu />
            </Navbar.Toggle>

            <Navbar.Collapse className="headerMenu" id="responsive-navbar-nav">
              <div className="menusWrap">
                <Nav className="me-auto">
                  <NavDropdown
                    title={headerData.solution.name}
                    id="collasible-nav-dropdown"
                    className={`soldropMenu ${[
                        ...serviceList.map((s) => {
                          return s.value.map((val) => val.path);
                        }),
                        "technologies",
                        "industries",
                      ].some((elem) => elem.includes(slug))
                        ? "active-group"
                        : ""
                      }`}
                    show={show}
                    onMouseEnter={device === "desktop" ? showDropdown : null}
                    onMouseLeave={device === "desktop" ? hideDropdown : null}
                    onToggle={device === "mobile" ? showDropdown : null}
                  >
                    <div className="solutionMenu">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        <div className="solmenuWrap">
                          <div className="soltabSide">
                            <Nav
                              variant="pills"
                              className="flex-column solPills"
                            >
                              <h6 className="solmenuTitel">
                                {getSolutionList.name}
                              </h6>
                              <Nav.Item>
                                <Nav.Link eventKey="first">
                                  <img
                                    src={servicemenuIocn}
                                    alt="Service menu"
                                  />
                                  {getSolutionList.value[0]}
                                </Nav.Link>
                              </Nav.Item>
                              <div className="solcontentSide mobile_menu">
                                <Tab.Content>
                                  <Tab.Pane eventKey="first">
                                    <div className="servmenuData">
                                      {/* services column one */}
                                      <div className="solmenuCol resourcesMenu">
                                        <ul className="solmenuList nav-item">
                                          <label>{serviceList[0].name}</label>
                                          {serviceList[0].value.map(
                                            (val, index) => (
                                              <li
                                                className="nav-link nav-link-pl-0"
                                                key={index}
                                              >
                                                <Link
                                                  className="nav-link nav-link-pl-0"
                                                  style={{
                                                    paddingLeft: "0 !important",
                                                  }}
                                                  to={`/${val.path}/`}
                                                >
                                                  {val.name}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                        <ul className="solmenuList nav-item">
                                          <label>{serviceList[1].name}</label>
                                          {serviceList[1].value.map(
                                            (val, index) => (
                                              <li
                                                className="nav-link nav-link-pl-0"
                                                key={index}
                                              >
                                                <Link
                                                  className="nav-link nav-link-pl-0"
                                                  to={`/${val.path}/`}
                                                >
                                                  {val.name}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      {/* services column one */}
                                      <div className="solmenuCol resourcesMenu">
                                        <ul className="solmenuList nav-item">
                                          <label>{serviceList[2].name}</label>
                                          {serviceList[2].value.map(
                                            (val, index) => (
                                              <li
                                                className="nav-link nav-link-pl-0"
                                                key={index}
                                              >
                                                <Link
                                                  className="nav-link nav-link-pl-0"
                                                  to={`/${val.path}/`}
                                                >
                                                  {val.name}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                        <ul className="solmenuList nav-item">
                                          <label>{serviceList[3].name}</label>
                                          {serviceList[3].value.map(
                                            (val, index) => (
                                              <li
                                                className="nav-link nav-link-pl-0"
                                                key={index}
                                              >
                                                <Link
                                                  className="nav-link nav-link-pl-0"
                                                  to={`/${val.path}/`}
                                                >
                                                  {val.name}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      {/* services column one */}
                                      <div className="solmenuCol resourcesMenu">
                                        <ul className="solmenuList nav-item">
                                          <label>{serviceList[4].name}</label>
                                          {serviceList[4].value.map(
                                            (val, index) => (
                                              <li
                                                className="nav-link nav-link-pl-0"
                                                key={index}
                                              >
                                                <Link
                                                  className="nav-link nav-link-pl-0"
                                                  to={`/${val.path}/`}
                                                >
                                                  {val.name}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                              <Nav.Item>
                                <Nav.Link eventKey="second">
                                  <img
                                    src={technologiesmenuIcon}
                                    alt="Service menu"
                                  />
                                  {/* <technologiesmenuIcon/> */}
                                  {getSolutionList.value[1]}
                                </Nav.Link>
                              </Nav.Item>
                              <div className="solcontentSide mobile_menu">
                                <Tab.Content>
                                  <Tab.Pane eventKey="second">
                                    <div className="techmenuData">
                                      {/* services column one */}
                                      <div className="solmenuCol resourcesMenu">
                                        <ul className="solmenuList nav-item">
                                          {technologyList2.map((val, index) => (
                                            <li key={index}>
                                              <Link
                                                className="nav-link"
                                                to={`/${val.path}`}
                                              >
                                                {val.name}
                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                        <ul className="solmenuList nav-item">
                                          {technologyList.map((val, index) => (
                                            <li key={index}>
                                              <Link
                                                className="nav-link"
                                                to={`/technologies/#${val.path}`}
                                              >
                                                {val.name}
                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      {/* services column one */}
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                              <Nav.Item>
                                <Nav.Link eventKey="third">
                                  <img
                                    src={technologiesmenuIcon}
                                    alt="Service menu"
                                  />
                                  {/* <technologiesmenuIcon/> */}
                                  {headerData.industry.name}
                                </Nav.Link>
                              </Nav.Item>
                              <div className="solcontentSide mobile_menu">
                                <Tab.Content>
                                  <Tab.Pane eventKey="third">
                                    <div className="techmenuData">
                                      {/* services column one */}
                                      <div className="solmenuCol resourcesMenu">
                                        <ul className="solmenuList nav-item">
                                          {headerData.industry.value.map(
                                            (data, index) => (
                                              <li key={index}>
                                                <Link
                                                  className="nav-link"
                                                  to={`/industries/#${data.path}`}
                                                >
                                                  {data.val}{" "}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      {/* services column one */}
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </Nav>
                          </div>

                          <div className="solcontentSide desktop_menu">
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <div className="servmenuData">
                                  {/* services column one */}
                                  <div className="solmenuCol resourcesMenu">
                                    <ul className="solmenuList nav-item servicesNav">
                                      <label>{serviceList[0].name}</label>
                                      {serviceList[0].value.map(
                                        (val, index) => (
                                          <li key={index}>
                                            <Link
                                              className="nav-link"
                                              to={`/${val.path}/`}
                                            >
                                              {val.name}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                    <ul className="solmenuList nav-item servicesNav">
                                      <label>{serviceList[1].name}</label>
                                      {serviceList[1].value.map(
                                        (val, index) => (
                                          <li key={index}>
                                            <Link
                                              className="nav-link"
                                              to={`/${val.path}/`}
                                            >
                                              {val.name}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                  {/* services column one */}
                                  <div className="solmenuCol resourcesMenu">
                                    <ul className="solmenuList nav-item servicesNav">
                                      <label>{serviceList[2].name}</label>
                                      {serviceList[2].value.map(
                                        (val, index) => (
                                          <li key={index}>
                                            <Link
                                              className="nav-link"
                                              to={`/${val.path}/`}
                                            >
                                              {val.name}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                    <ul className="solmenuList nav-item servicesNav">
                                      <label>{serviceList[3].name}</label>
                                      {serviceList[3].value.map(
                                        (val, index) => (
                                          <li key={index}>
                                            <Link
                                              className="nav-link"
                                              to={`/${val.path}/`}
                                            >
                                              {val.name}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                  {/* services column one */}
                                  <div className="solmenuCol resourcesMenu">
                                    <ul className="solmenuList nav-item servicesNav">
                                      <label>{serviceList[4].name}</label>
                                      {serviceList[4].value.map(
                                        (val, index) => (
                                          <li key={index}>
                                            <Link
                                              className="nav-link"
                                              to={`/${val.path}/`}
                                            >
                                              {val.name}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>


                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <div className="techmenuData">
                                  {/* services column one */}
                                  <div className="solmenuCol resourcesMenu d-md-flex justify-content-md-between">
                                    <ul className="solmenuList nav-item">
                                      {technologyList2.map((val, index) => (
                                        <li key={index}>
                                          <Link
                                            className="nav-link"
                                            to={`/${val.path}`}
                                          >
                                            {val.name}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                    <ul className="solmenuList nav-item">
                                      {technologyList.map((val, index) => (
                                        <li key={index}>
                                          <Link
                                            className="nav-link"
                                            to={`/technologies/#${val.path}`}
                                          >
                                            {val.name}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                  {/* services column one */}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="third">
                                <div className="techmenuData">
                                  {/* services column one */}
                                  <div className="solmenuCol resourcesMenu">
                                    <ul className="solmenuList nav-item servicesNav">
                                      {headerData.industry.value.map(
                                        (data, index) => (
                                          <li key={index}>
                                            <Link
                                              className="nav-link"
                                              to={`/industries/#${data.path}`}
                                            >
                                              {data.val}{" "}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                  {/* services column one */}
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </div>
                        </div>
                      </Tab.Container>
                    </div>
                  </NavDropdown>
                  <NavDropdown
                    title={headerData.platform.name}
                    id="collasible-nav-dropdown"
                    className={`resdropMenu ${[
                        "cloud-platforms",
                        "aws-public-support-statement",
                      ].includes(slug)
                        ? "active-group"
                        : ""
                      }`}
                    show={showIndustryList}
                    onMouseEnter={
                      device === "desktop" ? showDropdownIndustryList : null
                    }
                    onMouseLeave={
                      device === "desktop" ? hideDropdownIndustryList : null
                    }
                    onToggle={
                      device === "mobile" ? showDropdownIndustryList : null
                    }
                  >
                    <div className="resourcesMenu">
                      <Nav.Item>
                        {headerData.platform.value.map((data, index) =>
                          data.val === "AWS" ? (
                            <Link
                              key={index}
                              to={`/${data.path}/`}
                              className="nav-link"
                              eventKey="first"
                            >
                              {data.val}{" "}
                            </Link>
                          ) : (
                            <Link
                              key={index}
                              to={`/cloud-platforms/#${data.path}`}
                              className="nav-link"
                              eventKey="first"
                            >
                              {data.val}{" "}
                            </Link>
                          )
                        )}
                      </Nav.Item>
                    </div>
                  </NavDropdown>

                  <NavDropdown
                    title={headerData.resources.name}
                    id="collasible-nav-dropdown"
                    className={`resdropMenu ${slug?.startsWith("resource/") ? "active-group" : ""
                      }`}
                    show={show1}
                    onMouseEnter={device === "desktop" ? showDropdown1 : null}
                    onMouseLeave={device === "desktop" ? hideDropdown1 : null}
                    onToggle={device === "mobile" ? showDropdown1 : null}
                  >
                    <div className="resourcesMenu">
                      <Nav.Item>
                        {/* <Link to={`/resource/cloud-news/`} className="nav-link" eventKey="first">
                          <LatestnewsMenu />
                          {getResourcesList[0]} </Link>
                        <Link to={`/resource/press-and-media/`} className="nav-link" eventKey="second">
                          <BlogsMenu />
                          {getResourcesList[1]}</Link> */}
                        <Link
                          to={`/resource/blog/`}
                          className="nav-link"
                          eventKey="third"
                        >
                          <PressnewsMenu />
                          {getResourcesList[2]}
                        </Link>
                        <Link
                          to={`/resource/white-papers/`}
                          className="nav-link"
                          eventKey="fourth"
                        >
                          <WhitePapermenu />
                          {getResourcesList[3]}
                        </Link>
                      </Nav.Item>
                    </div>
                  </NavDropdown>

                  {/* WHY OPSIO MENU */}

                  <NavDropdown
                    title={headerData.whyopsio.name}
                    id="collasible-nav-dropdown"
                    className={`resdropMenu ${["why-opsio", "successstories"].includes(slug)
                        ? "active-group"
                        : ""
                      }`}
                    show={show2}
                    onMouseEnter={device === "desktop" ? showDropdown2 : null}
                    onMouseLeave={device === "desktop" ? hideDropdown2 : null}
                    onToggle={device === "mobile" ? showDropdown2 : null}
                  >
                    <div className="resourcesMenu">
                      <Nav.Item>
                        <Link
                          to={`/why-opsio/`}
                          className="nav-link "
                          eventKey="first"
                        >
                          <LatestnewsMenu />
                          {headerData.whyopsio.value[0]}{" "}
                        </Link>
                        <Link
                          to={`/successstories/`}
                          className="nav-link"
                          eventKey="first"
                        >
                          <BlogsMenu />
                          {headerData.whyopsio.value[1]}
                        </Link>
                      </Nav.Item>
                    </div>
                  </NavDropdown>
                  <div className={slug === "contact-us" ? "active-group" : ""}>
                    <Link to={`/contact-us/`} className="nav-link desktop_menu">
                      {headerData.contact}
                    </Link>
                  </div>

                  {/* WHY OPSIO MENU */}
                </Nav>
                <div className="searchInput">
                  <Select
                    autoSize={true}
                    onInputChange={(e) => setSearchValue(e)}
                    styles={styles}
                    onChange={(e) => navigate(`${e.value}`)}
                    options={searchValue ? options : []}
                    noOptionsMessage={(val) => headerData.searchNoOptions}
                    placeholder={headerData.search}
                    isSearchable="true"
                    components={{
                      DropdownIndicator,
                      MenuList: renderScrollbar,
                    }}
                    isClearable="true"
                  />
                </div>
                <MediaQuery maxWidth={1024}>
                  <div className="langOpt">
                    <LanguageIcon
                      flag={
                        langTitle === flags["en"]
                          ? "en"
                          : langTitle === flags["sv"]
                            ? "sv"
                            : "in"
                      }
                    />
                    <NavDropdown
                      title={langTitle}
                      id="collasible-nav-dropdown"
                      className="landropMenu"
                      show={show3}
                      onToggle={showDropdown3}
                    >
                      <div className="lanMenu">
                        {slug === "/" ? (
                          <Nav.Item>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["sv"])}
                              href={`https://opsio.se/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"sv"} />
                              {flags["sv"]}
                            </a>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["en"])}
                              href={`https://opsiocloud.com/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"en"} />
                              {flags["en"]}
                            </a>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["en-IN"])}
                              href={`https://opsio.in/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"in"} />
                              {flags["en-IN"]}
                            </a>
                          </Nav.Item>
                        ) : (
                          <Nav.Item>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["sv"])}
                              href={`https://opsio.se/${slug}/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"sv"} />
                              {flags["sv"]}
                            </a>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["en"])}
                              href={`https://opsiocloud.com/${slug}/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"en"} />
                              {flags["en"]}
                            </a>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["en-IN"])}
                              href={`https://opsio.in/${slug}/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"in"} />
                              {flags["en-IN"]}
                            </a>
                          </Nav.Item>
                        )}
                      </div>
                    </NavDropdown>
                  </div>
                </MediaQuery>
                <MediaQuery minWidth={1025}>
                  <div className="langOpt">
                    <LanguageIcon
                      flag={
                        langTitle === flags["en"]
                          ? "en"
                          : langTitle === flags["sv"]
                            ? "sv"
                            : "in"
                      }
                    />
                    <NavDropdown
                      title={langTitle}
                      id="collasible-nav-dropdown"
                      className="landropMenu"
                      show={show3}
                      onMouseEnter={showDropdown3}
                      onMouseLeave={hideDropdown3}
                    >
                      <div className="lanMenu">
                        {slug === "/" ? (
                          <Nav.Item>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["sv"])}
                              href={`https://opsio.se/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"sv"} />
                              {flags["sv"]}
                            </a>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["en"])}
                              href={`https://opsiocloud.com/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"en"} />
                              {flags["en"]}
                            </a>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["en-IN"])}
                              href={`https://opsio.in/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"in"} />
                              {flags["en-IN"]}
                            </a>
                          </Nav.Item>
                        ) : (
                          <Nav.Item>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["sv"])}
                              href={`https://opsio.se/${slug}/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"sv"} />
                              {flags["sv"]}
                            </a>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["en"])}
                              href={`https://opsiocloud.com/${slug}/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"en"} />
                              {flags["en"]}
                            </a>
                            <a
                              className="nav-link"
                              onClick={() => setLangTitle(flags["en-IN"])}
                              href={`https://opsio.in/${slug}/`}
                              eventKey="first"
                            >
                              <LanguageIcon flag={"in"} />
                              {flags["en-IN"]}
                            </a>
                          </Nav.Item>
                        )}
                      </div>
                    </NavDropdown>
                  </div>
                </MediaQuery>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </header>
      </div>
    </React.Fragment>
  );
};
export default Header;
