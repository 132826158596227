import React from "react";
import en from "../images/en-flag.svg";
import sv from "../images/sv-flag.svg";
import inFlag from "../images/in-flag.svg";

const LanguageIcon = ({ flag }) => {
  return (
    <img
      height={18}
      width={18}
      src={flag === "en" ? en : flag === "sv" ? sv : inFlag}
    />
  );
};

export default LanguageIcon;
